<template>
  <div class="Model">
    <div class="Model-title">
      <a-input-search
        v-model:value="value"
        placeholder="input search text"
        style="width: 240px"
        @search="onSearch"
      />
      <a-cascader
        v-model:value="value2"
        :options="options"
        placeholder="Select Address"
        style="width: 240px; margin: 0 30px"
      />
      <a-select style="width: 240px" placeholder="请选择" allowClear>
        <a-select-option value="1">近期更新</a-select-option>
        <a-select-option value="2">复制最多</a-select-option>
      </a-select>
    </div>
    <div class="Model-content">
      <a-row type="flex">
        <a-col flex="322px" style="margin-right: 15px">
          <div class="Model-content-left">
            <div class="Model-content-left-top">
              <div
                class="left-item"
                @click="handleClick(index)"
                v-for="(item, index) in leftList"
                :key="index"
                :class="{ active: current === index }"
              >
                <a-descriptions title="人力资源-员工招聘">
                  <template #extra>
                    <!-- <a-button type="primary">Edit</a-button> -->
                    <span> 已上线 </span>
                  </template>
                  <a-descriptions-item label="话术id">
                    1235
                  </a-descriptions-item>
                  <a-descriptions-item label="行业"
                    >人资教育/招聘平台</a-descriptions-item
                  >
                  <a-descriptions-item label="结果分析器"
                    >意向分析器2.0
                  </a-descriptions-item>
                  <a-descriptions-item label="更新时间"
                    >2021-06-29 08:24:45
                  </a-descriptions-item>
                  <a-descriptions-item label="复制次数"
                    >556次
                  </a-descriptions-item>
                </a-descriptions>
              </div>
            </div>
            <div class="Model-content-left-page">
              <a-pagination
                @change="handlePage"
                pageSize="6"
                :total="19"
                :current="currentPage"
                show-less-items
              />
            </div>
          </div>
        </a-col>
        <a-col flex="auto">
          <div class="Model-content-right">
            <a-tabs v-model:activeKey="activeKey">
              <a-tab-pane key="1" tab="主动话术">
                <a-row type="flex">
                  <a-col flex="130px" style="margin-right: 10px">
                    <div class="Model-content-right-title active">开场白</div>
                    <div class="Model-content-right-title">业务介绍</div>
                    <div class="Model-content-right-title">了解情况</div>
                    <div class="Model-content-right-title">询问需求</div>
                    <div class="Model-content-right-title">结束语境</div>
                  </a-col>
                  <a-col flex="auto">
                    <div class="auto-canvas">
                      <div class="canvas">
                        <div
                          class="canvas-content"
                          style="width: 4000px; height: 4000px"
                          @mousedown="down"
                          @mouseup="up"
                        >
                          <div class="center">中心区域</div>
                        </div>
                      </div>
                      <div class="tips">
                        <div>节点图标</div>
                        <a-button disabled>保存</a-button>
                      </div>
                    </div>
                  </a-col>
                </a-row>
              </a-tab-pane>
              <a-tab-pane key="2" tab="知识库" force-render
                >Content of Tab Pane 2</a-tab-pane
              >
              <a-tab-pane key="3" tab="多轮会话"
                >Content of Tab Pane 3</a-tab-pane
              >
              <a-tab-pane key="4" tab="全局设置"
                >Content of Tab Pane 3</a-tab-pane
              >
              <a-tab-pane key="5" tab="试听">Content of Tab Pane 3</a-tab-pane>
            </a-tabs>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, ref, nextTick, onMounted } from "vue";
const options = [
  {
    value: "zhejiang",
    label: "Zhejiang",
    children: [
      {
        value: "hangzhou",
        label: "Hangzhou",
        children: [
          {
            value: "xihu",
            label: "West Lake",
          },
        ],
      },
    ],
  },
  {
    value: "jiangsu",
    label: "Jiangsu",
    children: [
      {
        value: "nanjing",
        label: "Nanjing",
        children: [
          {
            value: "zhonghuamen",
            label: "Zhong Hua Men",
          },
        ],
      },
    ],
  },
];
export default {
  name: "Model",
  setup() {
    const state = reactive({
      value: "",
      value2: "",
      value3: "",
      current: 0,
      currentPage: 1,
      leftList: [1, 2, 3, 4, 5, 6],
      offsetXstart: 0,
      offsetYstart: 0,
    });
    const onSearch = () => {
      console.log("搜索");
    };
    const handleClick = (index) => {
      state.current = index;
    };
    const handlePage = (e) => {
      state.currentPage = e;
    };
    nextTick(() => {
      // let canvas=document.querySelector(".canvas-content")
      // // console.log(canvas)
      // canvas.addEventListener('mousemove',fun)
    });
    onMounted(() => {
      let canvas = document.querySelector(".canvas-content");
      console.log(canvas)
      canvas.scrollTo(0,3000);
      // canvas.scrollLeft = 2000;
    });
    // let fun=()=>{
    //   console.log("移动了")
    // }
    let down = (e) => {
      // console.log(e);
      state.offsetXstart = e.offsetX;
      state.offsetYstart = e.offsetY;
      console.log(state.offsetXstart, state.offsetYstart);
    };
    let up = (e) => {
      // console.log(e);
      console.log(
        e.offsetX - state.offsetXstart,
        e.offsetY - state.offsetYstart
      );
      if (
        Math.abs(e.offsetX - state.offsetXstart) >
        Math.abs(e.offsetY - state.offsetYstart)
      ) {
        if (e.offsetX - state.offsetXstart > 50) {
          console.log("右移");
        } else if (state.offsetXstart - e.offsetX > 50) {
          console.log("左移");
        }
      } else {
        if (e.offsetY - state.offsetYstart > 50) {
          console.log("下移");
        } else if (state.offsetYstart - e.offsetY > 50) {
          console.log("上移");
        }
      }
      // state.offsetXstart = e.offsetX;
      // state.offsetYstart = e.offsetY;
    };
    return {
      options,
      ...toRefs(state),
      onSearch,
      handleClick,
      handlePage,
      activeKey: ref("1"),
      down,
      up,
    };
  },
};
</script>

<style>
.Model {
  height: 100%;
}
.Model-title {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 15px;
}
.Model .Model-content {
  height: calc(100% - 77px);
}
.Model .Model-content .ant-col {
  background-color: #fff;
  height: 100%;
}
.Model .Model-content .ant-row {
  height: 100%;
}
.Model-content-left {
  padding: 15px;
  height: 100%;
}
.Model-content-left-top {
  height: calc(100% - 32px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 4px;
}
.Model-content-left-top::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.Model-content-left-top .left-item {
  /* border-bottom: 1px solid #ecedef; */
  margin-bottom: 10px;
  padding: 10px 10px 4px 10px;
  cursor: pointer;
  background-color: #f8f9fb;
}
.Model-content-left-top .left-item:last-child {
  margin-bottom: 6px;
}
.Model .active {
  border: 1px solid #39aefb;
  background-color: #fff;
}
.Model .spanActive {
  color: green;
}
.Model-content-left-top
  .ant-descriptions:hover
  > .ant-descriptions-view
  .ant-descriptions-row
  > td
  > span {
  color: #39aefb;
}
.Model-content-left-top
  .ant-descriptions:hover
  > .ant-descriptions-header
  .ant-descriptions-title {
  color: #39aefb;
}
.Model-content-left-top .ant-descriptions-header {
  margin-bottom: 8px;
}
.Model-content-left-top .ant-descriptions-row {
  display: flex;
  flex-direction: column;
}
.Model-content-left-top .ant-descriptions-row .ant-descriptions-item {
  padding-bottom: 6px;
}
.Model-content-left-page {
  text-align: right;
}
.Model-content-right {
  padding: 0px 10px 10px 10px;
}
.Model-content-right-title {
  background-color: #f8f9fb;
  margin-bottom: 15px;
  min-height: 100px;
  line-height: 100px;
  padding-left: 10px;
  border-radius: 3px;
}
.Model-content-right-title:hover {
  cursor: pointer;
  color: #39aefb;
}
.Model .canvas {
  background-color: #f8f9fb;
}
.Model .tips {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2px;
}
.Model-content-right,
.Model-content-right .ant-tabs {
  height: 100%;
}
.Model .ant-tabs .ant-tabs-top-content {
  height: calc(100% - 61px);
}
.auto-canvas {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.auto-canvas .canvas {
  height: calc(100% - 38px);
  position: relative;
  overflow: auto;
}
/* .canvas::-webkit-scrollbar {
  width: 8px;
  height: 8px;
} */
.canvas-content {
  position: absolute;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* color: #fff; */
}
.center {
  width: 200px;
  height: 200px;
  background-color: #fff;
  position: absolute;
  left: 2000px;
  top: 2000px;
}
</style>